import React, { ReactNode, useCallback } from 'react';
import { ContentCard, cardLinkPointerClass } from '../../components/styles';
import { SpacerV } from '../../composing/Spacing';
import MemoHeader from './MemoHeader';
import { Routes } from '../nav/constants';
import MemoView from './MemoView';
import MemoFooterActions from './MemoFooterActions';
import { TagItemType } from '@commonstock/common/src/types';
import TagCloud from '../profile/TagCloud';
import CardCommentSummary from '../../components/CardCommentSummary';
import { Formatter, memoMarkdown } from '../text-editor/csMarkdown';
import { css, cx } from '@linaria/core';
import { ParentTypes } from '@commonstock/common/src/api/comment';
import MentionsContext from '../text-editor/Mentions';
import { $BodyEmphasis, $h3 } from '../../theme/Types';
import { FeedReason, MemoFeedItem } from '@commonstock/common/src/api/feed';
import { destructMemoFeedItem } from '../memo/utils';
import { ModalScope, useEphemeralModal } from '../modal/Modal';
import { trackMemoViewAction } from '../analytics/events.v1';
import { useTrackFeedItemImpression } from '../analytics/events.v1/impression';
import LinkedTrades from 'src/scopes/trade/LinkedTrades';
import { CardLayoutTypes, isUpvoteLayout } from 'src/scopes/feed/constants';
import { FlexRow } from 'src/composing/Flex';
import dynamic from 'next/dynamic';
import CSLink from 'src/composing/CSLink';
import { isLoggedIn } from '@commonstock/common/src/auth';
import useLoggedOutModal from '../public/useLoggedOutModal';
import { JoinModalPublic } from '../public/JoinModalPublic';
import { useTheme } from 'src/theme/ThemeContext';
import ContentColumnActions from 'src/scopes/content/ContentColumnActions';
import { useInView } from 'react-intersection-observer';
const MemoDetail = dynamic(() => import('./MemoDetail'));
type Props = {
  memoFeedItem: MemoFeedItem;
  cardLayout?: CardLayoutTypes;
};

function MemoCard({
  memoFeedItem,
  cardLayout
}: Props) {
  const [inViewRef, inView] = useInView({
    triggerOnce: true
  });
  const {
    memo,
    reason_text,
    reason_code
  } = destructMemoFeedItem(memoFeedItem);
  useTrackFeedItemImpression(memoFeedItem, inView);
  if (!memo) return null;
  const isCollapsed = reason_code === FeedReason.Pinned;

  if (isCollapsed) {
    cardLayout = CardLayoutTypes.Default;
  }

  return <ContentCard ref={inViewRef} className={cx(isUpvoteLayout(cardLayout) && 'hasColumnActions', !isUpvoteLayout(cardLayout) && reason_text && hasReason)}>
      <MemoDetailLink memoFeedItem={memoFeedItem} cardLayout={cardLayout}>
        {isUpvoteLayout(cardLayout) ? <FlexRow>
            <ContentColumnActions feedItem={memoFeedItem} cardLayout={cardLayout} />
            <div className={cx(mainContentPadding, reason_text && hasReason)}>
              <MemoContent memoFeedItem={memoFeedItem} showFollowerAssets cardLayout={cardLayout} />
              <MemoFooterActions memoFeedItem={memoFeedItem} cardLayout={cardLayout} />
              {!!memo.comments?.count && !isCollapsed && <CardCommentSummary comments={memo.comments} parentType={ParentTypes.MEMO_PLUS} />}
            </div>
          </FlexRow> : <>
            <MemoContent memoFeedItem={memoFeedItem} showFollowerAssets cardLayout={cardLayout} />
            <MemoFooterActions memoFeedItem={memoFeedItem} />
            {!!memo.comments?.count && !isCollapsed && <CardCommentSummary comments={memo.comments} parentType={ParentTypes.MEMO_PLUS} />}
          </>}
      </MemoDetailLink>
    </ContentCard>;
}

type MemoContentProps = {
  memoFeedItem: MemoFeedItem;
  detail?: boolean;
  showFollowerAssets?: boolean;
  cardLayout?: CardLayoutTypes;
};
export function MemoContent({
  memoFeedItem,
  detail,
  showFollowerAssets,
  cardLayout
}: MemoContentProps) {
  const {
    memo,
    user,
    reason_code,
    reason_text,
    mentionAttachments,
    linkedTrades
  } = destructMemoFeedItem(memoFeedItem);
  if (!memo || !user) return null;
  const isCollapsed = reason_code === FeedReason.Pinned;
  return <MentionsContext.Provider value={mentionAttachments}>
      <MemoHeader memo={memo} user={user} feedItem={memoFeedItem} reason={reason_text} reasonCode={reason_code} detail={!!detail} showFollowerAssets={showFollowerAssets} cardLayout={cardLayout} />
      <SpacerV />
      <Formatter text={memo.title} mentions={mentionAttachments} processor={memoMarkdown} className={cardLayout === CardLayoutTypes.Upvote ? titleClassSmall : titleClass} />
      {!isCollapsed && <>
          <SpacerV rem={cardLayout === CardLayoutTypes.Upvote ? 0.25 : 1} />
          <MemoView preview={!detail} memoFeedItem={memoFeedItem} cardLayout={cardLayout} />
          <LinkedTrades feedItem={memoFeedItem} trades={linkedTrades} />
          <TagCloud tags={memo.tags} parentType={TagItemType.MEMO_PLUS} parentUuid={memoFeedItem.uuid} />
        </>}
    </MentionsContext.Provider>;
}
export default React.memo(MemoCard);
const titleClass = "t1hs8csk";
const titleClassSmall = "tl5cmhg";
const mainContentPadding = "m1t41zki";
const hasReason = "h1yscxtp";
export const MemoDetailLink = ({
  memoFeedItem,
  cardLayout,
  children
}: {
  memoFeedItem: MemoFeedItem;
  cardLayout?: CardLayoutTypes;
  children: ReactNode;
}) => {
  const {
    isMobile
  } = useTheme();
  const modal = useEphemeralModal();
  const onClick = useCallback((e?: any) => {
    e?.preventDefault();
    e?.stopPropagation();
    trackMemoViewAction(memoFeedItem.uuid);
    modal(<MemoDetail isModal memoFeedItem={memoFeedItem} cardLayout={cardLayout} />, {
      shallowUrl: Routes.memoDetail(memoFeedItem.uuid),
      scope: ModalScope.Details
    });
  }, [cardLayout, memoFeedItem, modal]);
  const onKeyUp = useCallback((e: React.KeyboardEvent<HTMLElement>) => e.keyCode === 32 ? onClick(e) : null, [onClick]);
  const loggedOutModal = useLoggedOutModal('Memo Card');
  const handleClick = useCallback((e: React.MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    if (!isLoggedIn()) {
      loggedOutModal('Join', <JoinModalPublic />);
      return;
    } // @NOTE: rIC will wait for default event behavior like clearing selecion, and only then it will check for selection lentgh


    requestIdleCallback(() => !document.getSelection()?.toString() && onClick(e), {
      timeout: 500
    });
  }, [loggedOutModal, onClick]);
  return isMobile || !isLoggedIn() ? <CSLink unstyled role="article" href={Routes.memoDetail(memoFeedItem.uuid)} tabIndex={0} className={cardLinkPointerClass}>
      {children}
    </CSLink> : // eslint-disable-next-line
  <article role="article" tabIndex={0} className={cardLinkPointerClass} onClick={handleClick} onKeyUp={onKeyUp}>
      {children}
    </article>;
};

require("../../../.linaria-cache/packages/oxcart/src/scopes/memo/MemoCard.linaria.module.css");