import React, { useCallback } from 'react';
import { CardIcon } from '../../components/styles';
import { Comment } from '../../theme/NewIcons';
import { TextCaptionEmphasis } from '../../composing/CSText';
import { Colors } from '../../theme/constants';
import { UnstyledButton } from '../../composing/CSButton';
import { css } from '@linaria/core';
import Tooltip from '../../components/Tooltip';
import { SpacerH, Spacing } from '../../composing/Spacing';
import { CardLayoutTypes, isUpvoteLayout } from 'src/scopes/feed/constants';
import { JoinModalPublic } from 'src/scopes/public/JoinModalPublic';
import useLoggedOutModal from 'src/scopes/public/useLoggedOutModal';
import { isLoggedIn } from '@commonstock/common/src/auth';
type Props = {
  count: number | undefined;
  onClick?: () => void;
  emptyText?: string;
  isActive?: boolean;
  cardLayout?: CardLayoutTypes;
  isReply?: boolean;
};
export function CommentIcon({
  count,
  onClick,
  emptyText,
  isActive,
  cardLayout,
  isReply
}: Props) {
  const loggedOutModal = useLoggedOutModal(`Comment`);
  const onClickIcon = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!isLoggedIn()) {
      e.preventDefault();
      e.stopPropagation();
      loggedOutModal('Join', <JoinModalPublic />);
      return;
    }

    if (onClick) {
      onClick();
      e.stopPropagation();
    }
  }, [loggedOutModal, onClick]);
  return <CardIcon>
      <Tooltip tip={`${count} Comment${count ? 's' : ''}`}>
        <UnstyledButton className={buttonClass} onClick={onClickIcon} aria-label={'Comment content'}>
          <Spacing margin={[0, 0.375, 0, 0]}>
            <Comment active={isActive} size={1.25} className={noFlexShrink} />
          </Spacing>
          {isUpvoteLayout(cardLayout) ? <>
              <SpacerH rem={0.125} />
              <TextCaptionEmphasis color={isActive ? Colors.IconHover : 'inherit'}>
                Comment
                {count ? ` • ${count}` : emptyText}
              </TextCaptionEmphasis>
            </> : <TextCaptionEmphasis color={isActive ? Colors.IconHover : 'inherit'}>
              {count ? `${count} ${isReply ? count > 1 ? 'Replies' : 'Reply' : ''}` : isReply ? 'Reply' : emptyText}
            </TextCaptionEmphasis>}
        </UnstyledButton>
      </Tooltip>
    </CardIcon>;
}
const buttonClass = "bjlonfn";
const noFlexShrink = "ni0yv3l";

require("../../../.linaria-cache/packages/oxcart/src/scopes/comments/CommentIcon.linaria.module.css");